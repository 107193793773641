
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { User } from '@/interfaces/models/User';
import { Venue } from '@/interfaces/models/Venue';
import { Permission } from '@/enums/Permission';
import UserFilter from '@/components/user/UserFilter.vue';

@Component({
  components: { UserFilter, VTable },
})
export default class UserTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: User[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  public headers: ColumnHeader[] = headers;

  public edit(item: User) {
    if (this.$can(Permission.USER_UPDATE)) {
      this.$router.push({ name: 'user.edit', params: { id: item._id }, query: { ...this.$route.query } });
    } else if (this.$can(Permission.USER_UPDATE_RESTRICTED)) {
      this.$router.push({ name: 'user.editSimple', params: { id: item._id }, query: { ...this.$route.query } });
    }
  }

  public venueNames(venues: string[]) {
    if (!venues || venues.length === 0) {
      return '';
    }
    const names: Array<string | undefined> = this.venues.map((v: Venue) => {
      if (venues && venues!.indexOf(v._id) !== -1) {
        return v.name;
      }
    });

    return names.filter((n: string | undefined) => n !== undefined).join(',');
  }
}
