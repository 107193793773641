export default [
  {
    text: 'user.table.name',
    value: 'name',
  },
  {
    text: 'user.table.email',
    value: 'email',
  },
  {
    text: 'user.table.code',
    value: 'code',
  },
  {
    text: 'user.table.venues',
    value: 'venues',
    type: 'slot',
  },
  {
    text: 'user.table.role',
    value: 'role',
  },
];
