
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import RoleTable from '@/components/role/RoleTable.vue';
import { User } from '@/interfaces/models/User';
import UserTable from '@/components/user/UserTable.vue';
import { Venue } from '@/interfaces/models/Venue';
import { Permission } from '@/enums/Permission';
import Filter from '@/interfaces/api/Filter';

const user = namespace('user');
const app = namespace('app');

@Component({
  components: { UserTable, RoleTable, VEmptyState, VWrapper },
})
export default class Role extends Vue {
  @user.State('items') public users!: User[];
  @user.Action('destroy') public deleteUser!: (u: { id: string }) => {};
  @user.Action('fetch') public getUsers!: any;
  @user.Action('setFilter') public setFilter!: any;
  @user.State((state) => state.pagination.total) public total!: number[];
  @user.State('filter') public filter!: Filter;

  @app.State('venues') public venues!: Venue[];

  public async create() {
    if (this.$can(Permission.USER_CREATE)) {
      this.$router.push({ name: 'user.create' });
    } else if (this.$can(Permission.USER_CREATE_RESTRICTED)) {
      this.$router.push({ name: 'user.createSimple' });
    }
  }

  public async onFilter(val: object) {
    this.$startLoading('user');
    this.setFilter({ ...this.filter, ...val });
    await this.getUsers({ page: 1 });
    this.$stopLoading('user');
  }

  public async onSearch(val: string) {
    this.setFilter({ ...this.filter, search: val });
    await this.getUsers({ page: 1 });
  }

  public async destroy(user: User) {
    this.$startLoading('user');
    await this.deleteUser({ id: user._id });
    this.$stopLoading('user');
  }

  public async mounted() {
    this.$startLoading('role');
    await this.getUsers({ page: 1 });
    this.$stopLoading('role');
  }
}
